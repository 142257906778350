import { useEffect, useState } from 'react';
import './App.css';
import banner from './assets/images/banner.png';
import logo from './assets/images/logo.png';
import ParticlesBackground from './component/particles/ParticlesBackground';


function App() {
  const url = 'https://api.ipregistry.co/?key=qhhe1jjkdawx0dt9'
  const [country, setCountry] = useState('');
  const [flag, setFlag] = useState('');

  useEffect(() => {
    const getCountryAndFlag = () => {
      fetch(url)
        .then(function (response) {
          return response.json();
        })
        .then(function (payload) {
          let country = payload.location.country.name;
          setCountry(country)
          let flag = payload.location.country.flag.noto;
          setFlag(flag)
        });
    }
    getCountryAndFlag();
  }, [])




  return (
    <div className='banner' style={{ backgroundImage: `url(${banner})` }}>
      <ParticlesBackground></ParticlesBackground>
      <div className='content'>
        <img src={logo} alt="" />
        <h1><span>{country}</span> <span><img width="40" src={flag} alt="" /></span> Own Crypto Wallet</h1>
        <h2>TheCryptoWallet</h2>
        <p>Best Crypto Wallet Coming Soon</p>
        <a href="mailto:hello@thecryptowallet.com" className='button'>Contact Us</a>
      </div>
    </div>
  );
}

export default App;
